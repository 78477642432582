import provider from "./config";

export const criarIntegração = async (payload, token) => {
  try {
    const response = await provider.post('/entidades/cadastro/integracao/', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const buscarLocEscrit = async (idEstab, token) => {
  try {
    const response = await provider.get('/entidades/loja/locescrit', {
      params: { idEstab },
      headers: { Authorization: `Bearer ${token}`}
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const buscarEstab = async (idEstab, token) => {
  try {
    const response = await provider.get(`/entidades/${idEstab}`, {
      headers: { Authorization: `Bearer ${token}`}
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const editEstab = async (idEstab, payload, token) => {
  try {
    const response = await provider.put(`/entidades/${idEstab}`, payload, {
      headers: { Authorization: `Bearer ${token}`}
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const buscarValorGastoMeta = async (idEstab, dateRange, token) => {
  let params = { idEstab: idEstab }
  if (dateRange) {
    params.since = dateRange[0].toISOString().split('T')[0];
    params.until = dateRange[1].toISOString().split('T')[0];
  }

  try {
    const response = await provider.get(`/entidades/meta`, {
      headers: { Authorization: `Bearer ${token}`},
      params: params
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

