import axios from 'axios';

const currentUrl = window.location.href;
let BASE_URL = '';

if (currentUrl.includes("reconecta.com.br")) {
  BASE_URL = "https://reconecta-api.devaribox.co/api/v1";
} else if (currentUrl.includes("devaribox.co")) {
  BASE_URL = "https://reconecta-api.devaribox.co/api/v1";
} else if (currentUrl.includes(":300")) {
  BASE_URL = "https://reconecta-api.devaribox.co/api/v1";
  // BASE_URL = "http://localhost:3000/api/v1";
}

const provider = axios.create({
  baseURL: BASE_URL,
});

export default provider;