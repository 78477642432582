import React, { createContext, useContext, useState, useEffect } from 'react';
import { addToast } from 'src/components/Notification';
import * as authService from '../providers/auth';
import { userService } from '../providers/user';
import { toast } from 'react-toastify';

const AuthContext = createContext({
  isAuthenticated: null,
  user: null,
  signIn: null,
  signOut: null,
  update: null,
});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    retrieveUser();
  }, []);

  function saveUser(user) {
    setUser(user);
    localStorage.setItem('@reconecta-app', JSON.stringify(user));
  }

  function retrieveUser() {
    const localUser = localStorage.getItem('@reconecta-app');

    if (localUser) {
      return setUser(JSON.parse(localUser));
    }
  }

  async function signIn(payload) {
    try {
      const result = await authService.login(payload);
      return saveUser({ token: result.token, ...result.entidade });
    } catch (err) {
      addToast(toast.TYPE.ERROR, err.message);
    }
  }

  function signOut() {
    setUser(null);
    return localStorage.clear();
  }

  async function update(payload) {
    // try {
    //   const result = await userService.update(user.id, payload, user.token);
    //   saveUser({ token: user.token, ...result });
    //   return true;
    // } catch (err) {
    //   throw Error(err);
    // }
  }
  
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        signIn,
        signOut,
        update,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export default AuthProvider;
